import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/luckysheet',
    name: 'luckysheet',
    component: () => import('@/views/luckysheet/index.vue')
  },
  
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    children: [
      {
        path: '/home/goods',
        name: 'goods',
        component: () => import('@/views/goods/index.vue'),
      }, {
        path: '/home/createGoods/:id?/:isDisabled?',
        name: 'createGoods',
        component: () => import('@/views/goods/create/index.vue'),
      },{
        path: '/home/goodsSort',
        name: 'goodsSort',
        component: () => import('@/views/goods/sort/index.vue'),
      },{
        path: '/home/goodsAttr',
        name: 'goodsSort',
        component: () => import('@/views/goods/storeAttr/index.vue'),
      },{
        path: '/home/photoGoods',
        name: 'photoGoods',
        component: () => import('@/views/photoGoods/index.vue'),
      },{
        path: '/home/associatedGoods',
        name: 'photoGoods/associatedGoods',
        component: () => import('@/views/photoGoods/associatedGoods/index.vue'),
      },{
        path: '/home/order',
        name: 'photoGoods/order',
        component: () => import('@/views/order/index.vue'),
      },{
        path: '/home/orderAddress',
        name: 'orderAddress/orderAddress',
        component: () => import('@/views/order/orderAddress.vue'),
      },{
        path: '/home/activityMp',
        name: 'activityMp/index',
        component: () => import('@/views/activity/mp/index.vue'),
      },{
        path: '/home/carouselChart',
        name: 'carouselChart/index',
        component: () => import('@/views/activity/carouselChart/index.vue'),
      },{
        path: '/home/designInspiration',
        name: 'designInspiration/index',
        component: () => import('@/views/activity/designInspiration/index.vue'),
      },{
        path: '/home/homePageSettings',
        name: 'homePageSettings/index',
        component: () => import('@/views/activity/homePageSettings/index.vue'),
      },{
        path: '/home/contactUs',
        name: 'contactUs/order',
        component: () => import('@/views/contactUs/index.vue'),
      }

    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
