import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import attrFrom from '@/components/attrFrom'
import modalAttr from '@/libs/modal-attr';
import { modalSure } from '@/libs/public'
Vue.config.productionTip = false
Vue.component('attrFrom', attrFrom)
import "./utils/flexible";
Vue.use(Element);
Vue.prototype.$modalAttr = modalAttr;
Vue.prototype.$modalSure = modalSure;



// 自定义实现String 类型的replaceAll方法
String.prototype.replaceAll = function(s1, s2) {
  return this.replace(new RegExp(s1, "gm"), s2);
}


Vue.use(Element, {
  size:  'mini'
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
