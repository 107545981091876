import axios from "axios";
import { Loading } from 'element-ui';
let loadingInstance = null;
const api = axios.create({
    // baseURL: "http://192.168.3.28:8888",
    // baseURL: "http://172.20.10.2:8888",
    // baseURL: "http://39.106.84.243:8082",
    // baseURL: "http://localhost:8083/api-goods",
    baseURL: "https://api.senlin8.com/api-goods",
    // baseURL: process.env.VUE_APP_API_URL,
})
api.interceptors.response.use((response) => {
    if (loadingInstance != null) {
        loadingInstance.close();
        loadingInstance = null;
    }
    return response.data;
}, () => {
    if (loadingInstance != null) {
        loadingInstance.close();
        loadingInstance = null;
    }
});
api.interceptors.request.use((request) => {
    if (loadingInstance == null) {
        loadingInstance = Loading.service({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }
    return request;
});

export default api;