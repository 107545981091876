
import axios from '@/utils/request'

/**
 * 商品规格 列表
 * @param pram
 */
export function templateListApi(params) {
    return axios.get(
  '/rule/list',
      {params}
    )
  }
  /**
   * 商品规格 删除
   * @param pram
   */
  export function attrDeleteApi(id) {
    return axios({
      url: `/rule/delete/${id}`,
      method: 'get'
    })
  }
  /**
   * 商品规格 新增
   * @param pram
   */
  export function attrCreatApi(data) {
    return axios({
      url: '/rule/save',
      method: 'POST',
      data
    })
  }
  /**
   * 商品规格 编辑
   * @param pram
   */
  export function attrEditApi(data) {
    return axios({
      url: '/rule/update',
      method: 'POST',
      data
    })
  }
  /**
   * 商品规格 详情
   * @param pram
   */
  export function attrInfoApi(id) {
    return axios({
      url: `admin/store/product/rule/info/${id}`,
      method: 'GET'
    })
  }